'use client';

import { icons, Theme } from '../../../models/Models';
import { tNumber } from '../../../service/EmissionsService';

import Card from '../Card';
import Icon from '../../icon/Icon';
import StatsBar from '../../statsBar/StatsBar';
import Text from '../../text/Text';
import styles from './AdvancedLinkCard.module.scss';
import useApplicationConfig from '@/contexts/useApplicationConfig';

interface IProps {
  link: string;
  percentage: number;
  amount: number;
  totalAmount?: number;
  unit?: string;
  description: string;
  icon: icons;
  currentYear?: number;
}

/**
 * DetailCard
 * @description is an extension of the Card base component
 * and contains a detailed visualization of a certain emission
 * category and its content.
 * @param IProps props
 * @return DetailCard
 */
const AdvancedLinkCard = (props: IProps) => {
  const {
    percentage,
    amount,
    totalAmount,
    unit,
    link,
    description,
    icon,
    currentYear,
  } = props;

  const componentClass = 'detail-card';

  const { D } = useApplicationConfig();

  const getPercentageSubTitle = () => {
    switch (link) {
      case '/klimagassutslipp':
        return `I ${currentYear} var utslippene ${tNumber(amount || 0, 3)} ${
          D.emissions.measurement
        } lavere enn i 1990`;
      case '/kjoretoy/ALL/0':
        return `${tNumber(amount || 0)} ${D.general.ofTotal} ${tNumber(
          totalAmount || 0
        )} ${D.vehicles.vehiclesAreEmissionfree}`;
      case '/ferger':
        return `${tNumber(amount || 0)} ${D.general.ofTotal} ${tNumber(
          totalAmount || 0
        )} ${D.ferries.ferriesAreEmissionfree}`;
      case '/energibruk':
        return `${tNumber(
          amount / 1000 || 0,
          3
        )} TWh av Norges energiforbruk på ${tNumber(
          totalAmount !== undefined ? totalAmount / 1000 : 0,
          3
        )} TWh er fornybar energi`;
      default:
        // eslint-disable-next-line no-console
        console.debug('No card link found!');
    }
    return '';
  };

  return (
    <Card
      className={styles[componentClass] + ' group'}
      link={link}
      theme={Theme.Light}
      gridStyle="column-space-between"
      disabled={false}
    >
      <section className={styles[`${componentClass}__top`]}>
        <Icon className={styles[`${componentClass}__top__icon`]} icon={icon} />
        <Text
          className={styles[`${componentClass}__top__header`]}
          theme={Theme.Dark}
          text={description}
          type="label"
          bold
          size="large"
          wrap="normal"
        />
      </section>
      <section className={styles[`${componentClass}__middle`]}>
        <Text
          className={styles[`${componentClass}__middle__header`]}
          theme={Theme.Dark}
          type="label"
          bold
          size="mega"
          text={`${
            link === '/klimagassutslipp'
              ? tNumber(percentage, 2)
              : tNumber(percentage, 3)
          } %`}
        />
        {unit && <Text type="h4" theme={Theme.Dark} text={unit} />}
      </section>
      <section className={styles[`${componentClass}__bottom`]}>
        <StatsBar
          theme={Theme.Green}
          text={{
            title: {
              text: '',
              anchor: 'top',
            },
            subTitle: {
              text: getPercentageSubTitle(),
              anchor: 'bottom',
            },
          }}
          fillPercentage={percentage || 0}
          darkBorder
        />
      </section>
      <section className={styles[`${componentClass}__arrow-button`]}>
        <Text
          text="Se alle detaljer"
          type="h4"
          size="small"
          theme={Theme.Dark}
        />
        <Icon
          className={
            styles[`${componentClass}__arrow-button__circle-graphic`] +
            'text-white group-hover:text-black'
          }
          icon="arrow"
          rotation={0}
        />
      </section>
    </Card>
  );
};

export default AdvancedLinkCard;
