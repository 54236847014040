import classnames from 'classnames/bind';
import { forwardRef, useId } from 'react';
import { Theme, texts, themeToString } from '../../models/Models';

import styles from './Text.module.scss';

interface TextProps {
  id?: string;
  className?: string;
  text?: string | number;
  bold?: boolean;
  disabled?: boolean;
  size?: 'tiny' | 'small' | 'default' | 'medium' | 'large' | 'huge' | 'mega';
  underline?: boolean;
  alignment?: 'align-left' | 'align-center' | 'align-right';
  type: texts;
  theme: Theme;
  style?: object;
  wrap?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap';
  isExternalLink?: boolean;
  href?: string | undefined;
  hover?: boolean;
}

const Text = forwardRef<any, TextProps>((props, ref) => {
  const {
    id,
    className,
    text,
    bold = false,
    disabled = false,
    underline = false,
    alignment,
    size,
    type,
    theme,
    style,
    wrap,
    isExternalLink = false,
    href,
    hover,
  } = props;

  const customPrefix = 'themeified';
  const cx = classnames.bind(styles);
  const defaultId = useId();

  const componentId = id || defaultId;

  const styling = cx(`${type}-${customPrefix}${hover ? '-hover' : ''}`, {
    [`${type}-${customPrefix}--${themeToString(theme)}`]:
      theme !== null && !disabled,
    [`${type}-${customPrefix}--${themeToString(theme)}--disabled`]:
      theme !== null && disabled,
    [`${type}-${customPrefix}--bold`]: bold,
    [`${type}-${customPrefix}--underline`]: underline,
    [`${type}-${customPrefix}--${size}`]: size,
    [`${type}-${customPrefix}--${alignment}`]: alignment,
    [`${type}-${customPrefix}--${wrap}`]: wrap,
    [`${type}-${customPrefix}--external-link`]: isExternalLink,
    [`${type}-${customPrefix}--${themeToString(theme)}--external-link`]:
      theme !== null && !disabled && isExternalLink,
    [`${className}`]: className,
  });

  switch (type) {
    case 'h1':
      return (
        <h1 id={componentId} className={styling} style={style} ref={ref}>
          {text}
        </h1>
      );
    case 'h2':
      return (
        <h2 id={componentId} className={styling} style={style} ref={ref}>
          {text}
        </h2>
      );
    case 'h3':
      return (
        <h3 id={componentId} className={styling} style={style} ref={ref}>
          {text}
        </h3>
      );
    case 'h4':
      return (
        <h4 id={componentId} className={styling} style={style} ref={ref}>
          {text}
        </h4>
      );
    case 'label':
      return (
        <span id={componentId} className={styling} style={style} ref={ref}>
          {text}
        </span>
      );
    case 'a':
      return (
        <a
          id={componentId}
          href={href}
          className={styling}
          style={style}
          ref={ref}
        >
          {text}
        </a>
      );
    case 'p':
    default:
      return (
        <p id={componentId} className={styling} style={style} ref={ref}>
          {text}
        </p>
      );
  }
});

Text.displayName = 'Text';

export default Text;
