'use client';

import { useEffect, useMemo, useRef, useState } from 'react';

import { IComponent } from '../../models/Models';
import clsx from 'clsx';

interface IProps extends IComponent {
  fill: number;
  round?: boolean;
  darkBorder?: boolean;
  hero?: boolean;
  fillColor?: string;
  backgroundColor?: string;
}

const Fillbar = (props: IProps) => {
  const {
    children,
    className,
    fill,
    round = false,
    darkBorder = false,
    fillColor = 'bg-black',
    backgroundColor,
  } = props;

  const [effectFill, setEffectFill] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setEffectFill(fill);
    }, 200);
  }, [fill]);

  const ref = useRef<HTMLDivElement>(null);

  const fillbarStyling = clsx(
    'absolute left-0 top-0 h-full',
    `${round && 'rounded-lg'}`,
    'duration-200 ease-in-out',
    `${fillColor || 'bg-black'}`,
    `${className}`
  );

  const renderBar = useMemo(
    () => (
      <span style={{ width: `${effectFill}%` }} className={fillbarStyling} />
    ),
    [effectFill, fillbarStyling]
  );

  return (
    <div
      ref={ref}
      className={`relative my-2 h-3 w-full ${backgroundColor} ${darkBorder && 'border border-black'} ${round && 'rounded-lg'}`}
    >
      {renderBar}
      {children && children}
    </div>
  );
};

export default Fillbar;
