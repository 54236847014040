import classnames from 'classnames/bind';
import { Theme, anchor } from '../../models/Models';

import Fillbar from '../fillbar/Fillbar';
import Text from '../text/Text';
import styles from './StatsBar.module.scss';

type alignments = 'default' | 'allLeft' | 'allLeftExtra' | 'spreadAlignment';

interface IProps {
  text: {
    title?: {
      text: string;
      anchor: anchor;
    };
    subTitle?: {
      text: string;
      anchor: anchor;
    };
  };
  round?: boolean;
  darkBorder?: boolean;
  alignment?: alignments;
  fillPercentage: number;
  theme: Theme;
}

/**
 * StatsBar
 * @description Is used to display a basic fill bar graphic
 * that will size itself based on parent size.
 * @param IProps props
 *  @param object props.text
 *  @param boolean props.thick (Default: false)
 *  @param number props.fillPercentage
 *  @param string props.alignment (Default: "default")
 *  @param Theme props.theme
 */
const StatsBar = (props: IProps) => {
  const {
    text,
    round = false,
    darkBorder = false,
    fillPercentage,
    alignment = 'default',
    theme,
  } = props;

  // ************************************
  // Properties
  // ************************************

  const componentClass = 'stats-bar';
  const cx = classnames.bind(styles);

  // ************************************
  // Functionality
  // ************************************

  const isLeftAlignment = () =>
    alignment === 'allLeft' || alignment === 'allLeftExtra';

  const isSpreadAlignment = () => alignment === 'spreadAlignment';

  const isDefaultAlignment = () => alignment === 'default';

  // ************************************
  // Render Functionality
  // ************************************

  const getTitle = () => {
    if (text.title) {
      return (
        <Text
          className={cx(`${componentClass}__text`, {
            [`${componentClass}__text--${alignment}`]: alignment,
          })}
          text={text.title.text}
          type="label"
          bold
          theme={Theme.Dark}
        />
      );
    }

    return null;
  };

  const getSubTitle = () => {
    if (text.subTitle) {
      return (
        <Text
          className={cx(`${componentClass}__text`, {
            [`${componentClass}__text--${alignment}`]: alignment,
          })}
          text={text.subTitle.text}
          type="label"
          theme={Theme.Dark}
        />
      );
    }

    return null;
  };

  // ************************************
  // Render
  // ************************************

  return (
    <div
      className={cx(componentClass, {
        [`${componentClass}--${alignment}`]: alignment,
      })}
    >
      {isLeftAlignment() && (
        <>
          <div
            className={cx(`${componentClass}--${alignment}__text-container`)}
          >
            {getSubTitle()}
            {getTitle()}
          </div>
          <Fillbar
            theme={theme}
            fillColor="bg-green"
            backgroundColor="bg-green-very-light"
            fill={fillPercentage}
            round={round}
            darkBorder={darkBorder}
          />
        </>
      )}
      {isDefaultAlignment() && (
        <>
          {getTitle()}
          <Fillbar
            theme={theme}
            fillColor="bg-green"
            backgroundColor="bg-green-very-light"
            fill={fillPercentage}
            round={round}
            darkBorder={darkBorder}
          />
          {getSubTitle()}
        </>
      )}
      {isSpreadAlignment() && (
        <>
          {getSubTitle()}
          <div
            className={cx(
              `${componentClass}--${alignment}__bar-and-text-container`
            )}
          >
            <Fillbar
              theme={theme}
              fillColor="bg-green"
              backgroundColor="bg-green-very-light"
              fill={fillPercentage}
              round={round}
              darkBorder={darkBorder}
            />
            {getTitle()}
          </div>
        </>
      )}
    </div>
  );
};

export default StatsBar;
